import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Section from 'components/common/Section';
import MyAccordion from 'components/common/MyAccordion';
import { AirtableMarkdown } from "components/common/utils/AirtableMarkdown";
import Image from 'components/common/utils/Image';
import cx from 'classnames';
import './styles.sass'
import { Charles, Standard } from 'types/index';

import './styles.sass'

type Props = {
  about_title: JSX.Element | string
  about_definition: JSX.Element | string
  bottomBorder: boolean
  aboutItems: Standard.AccordionItem[]
  about_definition_label?: JSX.Element | string
}

const AboutPathology = (props: Props) => {
  return (
    <Section className="pathology-about-section">
      <div id="about-anchor" className="pathology-about-anchor pathology-anchor" />
      <Container className="pathology-about-container">
        <h2 className="">{props.about_title}</h2>
        <Row className="pathology-about-accordion-definition-row justify-content-between">
          <Col id="about-accordion" xs={12} xl={5} className="about-accordion-column">
            <MyAccordion data={props.aboutItems} version="white" />
          </Col>
          <Col xs={12} xl={6}>
            <div className="about-definition-card">
              <div className="about-black-draw-wrapper">
                <Image className="about-black-draw" filename="new-images/black-pen-graph.svg"
                alt="black-pen-graph"
                title="black pen graph"/>
              </div>
              <div className="about-definition-card-label">{props.about_definition_label || 'Définition'}</div>
              <div>{props.about_definition}</div>
            </div>
          </Col>
        </Row>
        <div className={cx({"pathology-about-hr": props.bottomBorder } )} />
      </Container>
    </Section>
  );
};

export default AboutPathology;
