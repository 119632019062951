import axios from 'axios';
import _ from 'lodash';
import { omsAppUrl } from 'utils/siteConfig';

class ApiService {
    constructor() {
      this.apiInstance = axios.create({
        baseURL: omsAppUrl,
      });
  
      this.apiInstance.interceptors.request.use((config) => ({
        ...config,
        data: ApiService.decamelizeKeys(config.data),
      }));
  
      this.apiInstance.interceptors.response.use((config) => ({
        ...config,
        data: ApiService.camelizeKeys(config.data),
      }));
    }

    const 

    static camelizeKeys(obj) {
        return _.mapKeys(obj, (value, key) => _.camelCase(key));
    }

    static decamelizeKeys(obj) {
        return _.mapKeys(obj, (value, key) => _.snakeCase(key));
    }
  
    getConsultationNextFreeSlot(specialtySlug) {
      return this.apiInstance.get(`/api/v1/doctors/${specialtySlug}/next_free_time_slot`);
    }

  }
  
  export default new ApiService();